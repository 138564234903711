.widget-calendar__month {
}
.widget-calendar__month__nav {
  width: 100%;
  height: 24px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.widget-calendar__month__nav__prev,
.widget-calendar__month__nav__next {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #0094cc;
  border: 1px solid var(--theme-primary-color);
  background: #fff;
  background: var(--theme-content-section-background-color);
  cursor: pointer;
  position: absolute;
  top: 0;
}
.widget-calendar__month__nav__prev:hover,
.widget-calendar__month__nav__next:hover {
  background: rgba(0, 148, 204, 0.1);
}

.widget-calendar__month__nav__prev::after,
.widget-calendar__month__nav__next::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 7px 5px 0;
  border-color: transparent #0094cc transparent transparent;
  border-color: transparent var(--theme-primary-color) transparent transparent;
  position: absolute;
  top: 6px;
  left: 7px;
}
.widget-calendar__month__nav__prev:disabled,
.widget-calendar__month__nav__next:disabled {
  border: 1px solid #ddd;
}
.widget-calendar__month__nav__prev:disabled:hover,
.widget-calendar__month__nav__next:disabled:hover {
  background: #fff;
}
.widget-calendar__month__nav__prev:disabled::after,
.widget-calendar__month__nav__next:disabled::after {
  border-color: transparent #ddd transparent transparent;
}
.widget-calendar__month__nav__prev {
  left: 8px;
}
.widget-calendar__month__nav__next {
  right: 8px;
  transform: scale(-1, 1);
}
.widget-calendar__month__nav__title {
  font-size: 14px;
  font-weight: bold;
}

.widget-calendar__month__title {
  width: 100%;
  height: 20px;
  font-size: 12px;
  display: table;
  table-layout: fixed;
}
.widget-calendar__month__title p {
  color: #aaa;
  color: var(--theme-disabled-text-color);
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}
.widget-calendar__month__week {
  width: 100%;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  color: #aaa;
  display: table;
  table-layout: fixed;
}
.widget-calendar__month__week label {
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  position: relative;
}
.widget-calendar__month__week label p {
  position: relative;
  z-index: 1;
}
.widget-calendar__month__week label input {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: calc(50% - 16px);
  z-index: 0;
  opacity: 0;
}

.widget-calendar__month__week label.request,
.widget-calendar__month__week label.instant {
  cursor: pointer;
}
.widget-calendar__month__week label.blank {
}
.widget-calendar__month__week label.disabled p {
  color: #aaa;
  color: var(--theme-disabled-text-color);
}
.widget-calendar__month__week label.closed p {
  color: #aaa;
  color: var(--theme-disabled-text-color);
}
.widget-calendar__month__week label.fully_booked p {
  color: #fcb2a9;
}
.widget-calendar__month__week label.request p {
  color: #ffcc00;
  color: var(--theme-secondary-color);
}
.widget-calendar__month__week label.instant p {
  color: #0094cc;
  color: var(--theme-primary-color);
}

.widget-calendar__month__week label.request input:checked {
  background: #ffcc00;
  background: var(--theme-secondary-color);
  opacity: 1;
}
.widget-calendar__month__week label.instant input:checked {
  background: #0094cc;
  background: var(--theme-primary-color);
  opacity: 1;
}

.widget-calendar__month__week label.request input:checked + p,
.widget-calendar__month__week label.instant input:checked + p {
  color: #fff;
}

.widget-calendar__month__week label .promotion {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 0;
  border-color: #ff0045 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 4px;
}

.widget-calendar__month__week label .few {
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 4px;
  right: 0;
}
.widget-calendar__booking__allowed__text {
  height: 20px;
  font-size: 12px;
  color: #aaa;
}
@media screen and (min-width: 768px) {
  .widget-calendar__month__week label.request:hover input {
    background: rgba(255, 204, 0, 0.2);
    opacity: 1;
  }
  .widget-calendar__month__week label.instant:hover input {
    background: rgba(0, 148, 204, 0.1);
    opacity: 1;
  }
}

/*
@media screen and (min-width: 415px) {
  .widget-calendar__month__week {
    height: 64px;
  }
  .widget-calendar__month__week label {
    line-height: 64px;
  }
  .widget-calendar__month__week label p {
    font-size: 16px;
  }
  .widget-calendar__month__week label input {
    width: 48px;
    height: 48px;
    top: 8px;
    left: calc(50% - 24px);
  }
}
*/
