.widget-calendar-selectTitle {
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  color: var(--theme-disabled-text-color);
}
.widget-calendar-pax-select {
  width: 100%;
  min-height: 32px;
  border-bottom: 1px solid #333;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-calendar-select:hover {
  background: rgba(60, 64, 67, 0.1);
}
.widget-calendar-select {
  width: 100%;
  min-height: 32px;
  border-bottom: 1px solid #333;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-calendar-select:hover {
  background: rgba(60, 64, 67, 0.1);
}
.widget-calendar-select__ttl,
.widget-calendar-selectTitle {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
}
.widget-calendar-select__num {
  width: 48px;
  margin-left: 8px;
  padding-right: 16px;
  position: relative;
  flex-shrink: 0;
}
.widget-calendar-select__num::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 2px);
  right: 8px;
}

.widget-calendar-select__item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
}
.widget-calendar-select__num {
  width: 48px;
  margin-left: 8px;
  padding-right: 16px;
  position: relative;
  flex-shrink: 0;
}
.widget-calendar-select__num::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 2px);
  right: 8px;
}
.widget-calendar-selectRow__item {
  width: 100%;
  height: 100%;
  padding-left: 4px;
  background: transparent;
  position: relative;
  cursor: pointer;
}
.widget-calendar-select::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #333 transparent transparent transparent;
  position: absolute;
  top: 14px;
  right: 8px;
}

.widget-calendar {
  width: 100%;
  max-width: 704px;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  margin: 0 auto;
}
.widget-calendar__headline {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  border-radius: 6px 6px 0 0;
  background: #0094cc;
  background: var(--theme-primary-color);
}
.widget-calendar__header__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-calendar__header__close span {
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  display: block;
  position: relative;
}
.widget-calendar__header__close:hover span {
  background: rgba(255, 255, 255, 0.3);
}
.widget-calendar__header__close span::before,
.widget-calendar__header__close span::after {
  content: '';
  width: 16px;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  top: 11px;
  left: 4px;
}
.widget-calendar__header__close span::before {
  transform: rotate(45deg);
}
.widget-calendar__header__close span::after {
  transform: rotate(-45deg);
}

.widget-calendar__main {
  padding: 8px 16px;
  position: relative;
}
.widget-calendar__booking {
  padding: 0px 4px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  background: #ff0045;
  position: absolute;
  top: 8px;
  right: 16px;
}
.widget-calendar__block {
}
.widget-calendar__block.product__name {
  margin-bottom: 16px;
}
.widget-calendar__title {
  font-size: 14px;
  font-weight: bold;
  color: #0094cc;
  color: var(--theme-primary-color);
  margin-bottom: 4px;
}
.widget-calendar__number > li {
  width: 100%;
  margin-bottom: 8px;
}
.widget-calendar__number > li:nth-child(odd) {
  margin-right: 16px;
}
.widget-calendar__number {
  margin-bottom: 16px;
  position: relative;
}
.widget-calendar__number__display {
  width: 260px;
  text-align: center;
  color: #0094cc;
  color: var(--theme-primary-color);
  border-radius: 4px;
  border: 1px solid #0094cc;
  border: 1px solid var(--theme-primary-color);
  padding: 4px;
  margin: 0 auto;
  position: relative;
  word-break: break-all;
  cursor: pointer;
}
.widget-calendar__number__overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.widget-calendar__number__select {
  width: 100%;
  max-width: 704px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,20%);
  background: #fff;
  background: var(--theme-content-section-background-color);
  padding: 16px;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 100;
}
.widget-calendar__number__select.is-active { display: block; }
.widget-calendar__number__select ul {
  
}
.widget-calendar__number__select ul li + li {
  margin-top: 16px;
}
.widget-calendar__comment {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.widget-calendar__comment__item {
  width: 50%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.widget-calendar__comment__item__color {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 4px;
}
.widget-calendar__comment__item__color.yellow {
  background: #ffcc00;
  background: var(--theme-secondary-color);
  border-radius: 100%;
}
.widget-calendar__comment__item__color.blue {
  background: #0094cc;
  background: var(--theme-primary-color);
  border-radius: 100%;
}
.widget-calendar__comment__item__color.fully_booked {
  background: #fcb2a9;;
  border-radius: 100%;
}
.widget-calendar__comment__item__color.promotion {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 0 0;
  border-color: #ff0045 transparent transparent transparent;
  border-radius: 0;
}
.widget-calendar__comment__item__color.few img {
  width: inherit;
  height: inherit;
  vertical-align: top;
}
.widget-calendar__comment__item__name {
  font-size: 12px;
  font-weight: bold;
  margin-right: 4px;
}
.widget-calendar__comment__item__ic {
  width: 16px;
  height: 16px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #aaa;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-calendar__comment__item__ic:hover + .widget-calendar__comment__item__info {
  display: block;
}
.widget-calendar__comment__item__info {
  display: none;
  width: 240px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(60, 64, 67, 0.1);
  position: absolute;
  bottom: 16px;
  padding: 6px;
  z-index: 1;
}
.widget-calendar__comment__item__info.first {
  left: 0;
}
.widget-calendar__comment__item__info.last {
  right: -16px;
}
.widget-calendar-error {
  font-size: 12px;
  font-weight: bold;
  color: #ff0045;
}

.widget-calendar__time {
  position: relative;
}
.widget-calendar__time__badge__loader {
  padding: 0 8px;
  position: absolute;
  bottom: 4px;
  right: 24px;
}
.widget-calendar__time__badge {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 20px;
  border-radius: 10px;
  background: #0094cc;
  background: var(--theme-primary-color);
  padding: 0 8px;
  position: absolute;
  bottom: 4px;
  right: 24px;
}
.widget-calendar__time__badge.instant {
  background: #0094cc;
  background: var(--theme-primary-color);
}
.widget-calendar__time__badge.request {
  background: #ffcc00;
  background: var(--theme-secondary-color);
}
.widget-calendar__time__badge.unavailable {
  background: #ff0045;
}

.widget-calendar__time__badge:hover + .widget-calendar__comment__item__info {
  display: block;
}
.widget-confirmation__type__badge {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 20px;
  border-radius: 10px;
  padding: 0 8px;
  margin-right: 4px;
}
.widget-confirmation__type__badge.instant {
  background: #0094cc;
  background: var(--theme-primary-color);
}
.widget-confirmation__type__badge.request {
  background: #ffcc00;
  background: var(--theme-secondary-color);
}
.widget-confirmation__type__badge.unavailable {
  background: #ff0045;
}

.widget-calendar__button {
  width: 100%;
  max-width: 400px;
  height: 48px;
  font-size: 18px !important;
  font-weight: bold;
  color: #fff;
  background: #ffcc00;
  background: var(--theme-secondary-color);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  margin: 8px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.widget-calendar__button:hover {
  opacity: 0.8;
}
.widget-calendar__button:disabled {
  background: #ddd;
}
.widget-calendar__button:disabled:hover {
  opacity: 1;
}

.widget-calendar__price {
  padding-bottom: 8px;
  margin: 8px 16px 0px 16px;
}
.widget-calendar__price__item {
  border-bottom: 1px solid #ddd;
  padding: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-calendar__price__item:last-child {
  border-bottom: none;
}
.widget-calendar__price__item__ttl {
  font-weight: bold;
}
.widget-calendar__price__item__money {
  text-align: right;
  font-weight: bold;
  display: block;
  word-break: keep-all;
}

/*
@media screen and (min-width: 415px) {
  body {
    background: #f9f9f9;
  }
  .widget-calendar {
    margin: 16 auto;
  }
  .widget-calendar__button {
    margin: 16px auto;
  }
}
*/
