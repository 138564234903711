* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}
a {
  /* display: block; */
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
img {
  /* width: 100%;
  height: auto; */
  vertical-align: middle;
}
svg {
  width: inherit;
  height: inherit;
  vertical-align: top;
}
section {
  display: block;
}
html {
  font-size: 16px;
}
input,
textarea,
select,
button {
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  width: 40px;
  height: 40px;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
:placeholder-shown,
::-webkit-input-placeholder,
:-ms-input-placeholder {
  color: #aaa !important;
}
select::-ms-expand {
  display: none;
}
body {
  font-family: Helvetica, Arial, Verdana, Roboto, 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro',
    'メイリオ', 'Meiryo', sans-serif;
  -webkit-text-size-adjust: 100%;
  color: #333;
  background: #f9f9f9;
  font-size: 14px;
  line-height: 1.5;
  /* letter-spacing: 0.1em; */
  vertical-align: middle;
  overflow-x: hidden;
}
.base-wrap {
}
.base-main {
  width: 100%;
  min-height: calc(100vh - 64px - 160px - 64px + 8px);
  margin-bottom: 64px;
  display: block;
}

.base-archive {
  width: 100%;
  max-width: 1240px;
  padding: 0 40px;
  margin: 0 auto;
  display: block;
}
.base-archive__list {
  padding: 16px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.base-archive__list__item {
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
}
.col-1 {
  width: calc((100% - 16px * 0) / 1);
}
.col-2 {
  width: calc((100% - 16px * 1) / 2);
}
.col-3 {
  width: calc((100% - 16px * 2 - 1px) / 3);
}
.col-4 {
  width: calc((100% - 16px * 3) / 4);
}
.col-2:not(:nth-child(2n)) {
  margin-right: 16px;
}
.col-3:not(:nth-child(3n)) {
  margin-right: 16px;
}
.col-2:not(:nth-child(4n)) {
  margin-right: 16px;
}
.maincolor-txt {
  color: #0094cc;
  color: var(--theme-primary-color);
}
.maincolor-border {
  border-color: #0094cc;
  border-color: var(--theme-primary-color);
}
.maincolor-bg {
  background: #0094cc;
  background: var(--theme-primary-color);
}
.maincolor-arrow::after {
  border-top-color: #0094cc;
  border-top-color: var(--theme-primary-color);
}
.maincolor-arrow2::after {
  border-top-color: #0094cc;
  border-top-color: var(--theme-primary-color);
  border-right-color: #0094cc;
  border-right-color: var(--theme-primary-color);
}
.maincolor-ic svg g * {
  fill: #0094cc;
  fill: var(--theme-primary-color);
}
.subcolor-bg {
  background: #daeff8;
  background: var(--theme-footer-background-color);
}

.base-subHeader {
  padding: 16px 0;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border-bottom: 1px solid #ddd;
}
.base-subHeader__inner {
  width: calc(100% - 320px - 32px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-subHeader__back {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-subHeader__back__ic {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.base-subHeader__back__ic img {
  width: 24px;
  height: 24px;
  display: block;
}
.base-subHeader__feature {
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  margin-left: auto;
}
article.base-single {
  margin: 0 auto 64px auto;
}
.base-single {
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.base-single__main {
  width: calc(100% - 320px - 32px);
}
.base-single__side {
  margin-top: -48px;
  width: 320px;
  position: relative;
}
.base-single__side__sticky {
  position: sticky;
  top: 16px;
  width: 100%;
  /* height: 800px; */
  background: #fff;
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 6px;
}
.base-single__card {
  width: 100%;
  background: #fff;
  background: var(--theme-content-section-background-color);
  box-shadow: 0 4px 8px rgba(60, 64, 67, 0.1);
  border-radius: 6px;
  margin-top: 16px;
  overflow: hidden;
}
.base-single__title {
  position: relative;
}
.base-single__title__h1 {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.5;
  padding-top: 16px;
  /* padding-bottom: 16px; */
  position: relative;
  z-index: 1;
}
.base-single__title::after {
  content: '';
  display: block;
  width: 300vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 150%;
  right: 150%;
  margin-left: -150vw;
  margin-right: -150vw;
  /* background: #EEF3F6; */
}
.base-single__section {
  margin-bottom: 16px;
}
.base-single__section__title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  color: rgba(0, 0, 0, 0);
  color: var(--theme-subheader-color);
  padding: 16px;
}
.base-single__fixed {
  display: none;
}
.base-related {
  padding: 32px 0;
}
.base-related.white {
  background: #fff;
}
.base-related__headline {
  font-size: 32px;
  text-align: center;
  margin-bottom: 32px;
}
.base-related__list {
  width: 100%;
  max-width: 1280px;
  padding: 0 32px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.base-related__list__item {
  width: calc((100% - 80px) / 3);
  max-width: 340px;
}
.base-related__list__item:not(:nth-child(3n)) {
  margin-right: 40px;
}
.base-key__text {
  color: var(--theme-subheader-color);
}
.base-key__text svg g * {
  fill: #0094cc;
  fill: var(--theme-subheader-color);
}
@media screen and (max-width: 820px) {
  .scroll-target-section {
    scroll-margin-top: 56px;
  }
}
@media screen and (max-width: 768px) {
  .base-main {
    /* padding-top: 56px !important; */
  }
  .base-archive {
    width: 100%;
    padding: 16px;
  }
  .base-archive__list__item {
    width: calc(50% - 8px) !important;
    margin-bottom: 16px;
  }
  .col-3:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .col-2:not(:nth-child(4n)) {
    margin-right: 0;
  }
  .col-3:not(:nth-child(2n)) {
    margin-right: 0px;
  }
  .col-2:not(:nth-child(2n)) {
    margin-right: 16px;
  }

  .base-subHeader {
    padding: 16px;
  }
  .base-subHeader__back span {
    display: none;
  }
  .base-subHeader__inner {
    width: 100%;
  }
  .base-subHeader__back {
    /* width: 100%; */
  }
  .base-single {
    width: 100%;
    padding: 0;
    flex-wrap: wrap;
  }
  .base-single__main {
    width: 100%;
  }
  .base-single__side {
    width: 100%;
    padding: 16px;
    margin-top: 0 !important;
  }
  .base-single__card {
    border-radius: 0;
  }
  .base-single__title__h1 {
    width: 100%;
    font-size: 18px;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .base-single__title::after {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .base-single__fixed {
    width: 100%;
    height: 64px;
    background: rgba(0, 0, 0, 0.6);
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    transform: translateY(100%);
    transition: transform 0.4s ease;
  }
  .base-single__fixed.is-active {
    transform: translateY(0);
  }
  .base-single__fixed__btn {
    width: calc(100% - 32px);
    max-width: 480px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    border-radius: 4px;
    background: #ffcc00;
    background: var(--theme-secondary-color);
    margin: 8px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .base-single__fixed__btn.disabled {
    color: #fff;
    border-radius: 4px;
    background: #aaa;
    pointer-events: none;
  }
  .base-related__list {
    padding: 0 16px;
    justify-content: flex-start;
  }
  .base-related__list__item {
    width: calc((100% - 16px) / 2);
    max-width: 600px;
    margin-bottom: 32px;
  }
  .base-related__list__item:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .base-related__list__item:nth-child(odd) {
    margin-right: 16px;
  }
}
@media screen and (max-width: 600px) {
  .base-archive {
    width: 100%;
    padding: 16px;
  }
  .base-archive__list__item {
    width: 100% !important;
    margin-bottom: 16px;
  }
  .col-2:not(:nth-child(2n)) {
    margin-right: 0;
  }
  .col-3:not(:nth-child(3n)) {
    margin-right: 0;
  }
  .col-2:not(:nth-child(4n)) {
    margin-right: 0;
  }
  .base-related__headline {
    font-size: 24px;
    text-align: left;
    padding: 0 16px;
    margin-bottom: 16px;
  }
  .base-related__list__item {
    width: 100%;
    margin-bottom: 32px;
  }
  .base-related__list__item:nth-child(odd) {
    margin-right: 0;
  }
}

.base-padding__8-8 {
  padding: 8px;
}
.base-padding__8-16 {
  padding: 8px 16px;
}
.base-padding__16-8 {
  padding: 16px 8px;
}
.base-padding-16 {
  padding: 16px;
}
.base-padding-16-32 {
  padding: 16px 32px;
}
.base-padding-32-16 {
  padding: 32 16px;
}

.base-marginTop-8 {
  margin-top: 8px;
}
.base-marginTop-16 {
  margin-top: 16px;
}
.base-marginTop-32 {
  margin-top: 32px;
}

.base-marginBottom-8 {
  margin-bottom: 8px;
}
.base-marginBottom-16 {
  margin-bottom: 16px;
}
.base-marginBottom-32 {
  margin-bottom: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.newline {
  white-space: pre-line;
}
